<template>
  <div id="activityType">
    <el-row style="background-color: #ffffff">
      <content-title :nav="nav"></content-title>
    </el-row>
    <el-button type="warning" @click="add()" plain>新增活动</el-button>
    <div>
      <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark">
        <el-table-column prop="id" label="id"> </el-table-column>
        <el-table-column prop="name" label="活动名称"> </el-table-column>
        <el-table-column prop="type" label="活动类型"></el-table-column>
        <el-table-column width="300px" label="操作">
          <template slot-scope={row}>
            <!-- <samp style="color: #FF7E00;margin: 0 10px;"> 编辑</samp> -->
            <samp style="color: #F56C6C" @click="update(row)"> 下架</samp>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 分页 -->
    <paging-fy
      :currentPage="1"
      :total="total"
    ></paging-fy>   

    <el-dialog title="添加大屏登录" :visible.sync="addVisable">
      <el-form>
        <el-form-item label="名称" >
          <el-input v-model="name" ></el-input>
        </el-form-item>
        <el-form-item label="类型" >
          <el-input v-model="type" ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addVisable = false">取 消</el-button>
        <el-button type="primary" @click="addType">确 定</el-button>
      </div>
    </el-dialog>


  </div>
</template>

<script>
export default {
  data() {
    return {
      nav: { firstNav: "活动管理中心", secondNav: "活动类型管理" },
      tableData: [],
      total:0,
      addVisable:false,
      name:'',
      type:''
    };
  },
  mounted(){
    this.getTypeOfActivity()
  },
  methods: {
    add(){
      this.addVisable=true
    },
    getTypeOfActivity() {
      let url =
        "/user/businessActivityType/queryNoRepeat";
      this.$axios.get(url).then((res) => {
        // console.log(res);
        this.tableData = res.data.data;
        this.total = res.data.data.length
      });
    },
    update(row){
      let url = "/user/businessActivityType/update";
      let data = {
        id:row.id,
        status:1
      }
      this.$axios.post(url, data).then((res) => {
        // console.log(res);
        if (res.data.code == 0) {
          this.getTypeOfActivity()
          this.$message({ type: 'success', message: '成功' })
        } else {
          this.$message({ type: 'info', message: res.data.message })
        }
      });
    },
    addType(){
      let url = "/user/businessActivityType/insert";
      let data = {
        "type": this.type,
        "name": this.name,
        "title": "",
        "status": "2",
        "is_new_customers": false,
        "coupon_time": "",
        "tags": "",
        "background_image": "",
        "activity_description": "",
        "source": "",
        "descr": "",
        "start_time": new Date().Format('yyyy-MM-dd hh:mm:ss')

      }
      console.log(data);
      this.$axios.post(url, data).then((res) => {
        // console.log(res);
        if (res.data.code == 0) {
          this.getTypeOfActivity()
          this.$message({ type: 'success', message: '添加成功' })
        } else {
          this.$message({ type: 'info', message: res.data.message })
        }
      });
      this.addVisable=false
    }
  }
};
</script>

<style lang="less">
#activityType {
  background-color: #ffffff;
  padding: 0 20px;
}
.cell {
  text-align: center;
}
</style>
